import store from "../store";

const tenant = {
  getApiBaseUrl() {
    return process.env.VUE_APP_API.replace(
      "{{domain}}",
      window.location.hostname
    );
  },
  getTenantName() {
    return "vivienvance"; // TODO
  },
  getTenantUrl() {
    return store.state.webshop_base_url;
  },
};

export default tenant;
