import http from "@/modules/http";
import store from "../../store";
import { Button } from "./button";
import tenant from "../tenant";

const apps = {
  events: {},
  hello() {
    alert("hello world");
  },
  getWarehouses() {
    return store.state.warehouses;
  },
  getWarehouse() {
    return store.state.topBar.warehouse;
  },
  callEvent(event, ...data) {
    if (this.events[event] === undefined) {
      return;
    }
    for (const methodKey of Object.keys(this.events[event])) {
      this.events[event][methodKey](...data);
    }
  },
  getHookUrl(appId, url) {
    return `${tenant.getApiBaseUrl()}/hooks/app/${appId}/${url}`;
  },
  fetch(appId, url, data, successBadge, method) {
    return http.fetch(`/apps/${appId}/${url}`, data, successBadge, method);
  },
  normalFetch(url, data, successBadge, method) {
    return http.fetch(url, data, successBadge, method);
  },
  searchProducts(q) {
    return new Promise((resolve, reject) => {
      if (q.length < 3) {
        reject(null);
      }
      http
        .fetch("/products/products/search", {
          q: q,
          //   language: this.$i18n.locale,
        })
        .then((data) => {
          resolve(data);
        })
        .catch((data) => {
          reject(data);
        });
    });
  },
  subscribe(event, method, name = null) {
    if (this.events[event] === undefined) {
      this.events[event] = {};
    }
    if (name === null) {
      name = Object.keys(this.events[event]).length + "-" + Math.random();
    }
    this.events[event][name] = method;
  },
  unsubscribe(event, name) {
    if (this.events[event] && this.events[event][name]) {
      delete this.events[event][name];
    }
  },
  showError(title, text) {
    store.commit("setApiError", { title: title, message: text });
  },
  showSuccess(title, text) {
    store.commit("setApiSuccess", { title: title, message: text });
  },
  createModal(appId, title, component, data = null) {
    store.commit("showModal", {
      title: title,
      component: component,
      appId: appId,
      data: data,
    });
  },
  hideModal() {
    store.commit("showModal", null);
  },
  storeValue(appId, key, value) {
    store.commit("storeAppData", { appId: appId, key: key, value: value });
  },
  getValue(appId, key) {
    if (store.state.appData[appId] === undefined) {
      return undefined;
    }
    return store.state.appData[appId][key];
  },
  getButton(name, type, onClick) {
    return new Button(name, type, onClick);
  },
};

export default apps;
